var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"8","offset-xl":"2"}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","offset-md":"6"}},[_c('v-text-field',{attrs:{"append-icon":_vm.mdiMagnify,"clearable":"","placeholder":"Search"},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.query,"loading":_vm.isLoading,"items":_vm.crmEntries,"single-expand":true,"show-expand":"","item-key":"id","sort-by":['lastOrderDate'],"sort-desc":[true],"custom-filter":_vm.$constants.VUETIFY.CUSTOM_SEARCH,"items-per-page":_vm.footerProps.itemsPerPage,"footer-props":_vm.footerProps},on:{"update:items":function($event){_vm.crmEntries=$event}},scopedSlots:_vm._u([{key:"item.lastOrderDate",fn:function(props){return [_c('span',{domProps:{"innerHTML":_vm._f("dateHtml")(props.item.lastOrderDate)}}),_c('b',[_vm._v("("+_vm._s(_vm.daysAgo( props.item.lastOrderDate ))+")")])]}},{key:"item.identifier",fn:function(props){return [_c('router-link',{staticClass:"font-weight-bold text-decoration-none",attrs:{"to":'/admin/orders?itemsPerPage=50&page=1&query=' +
                                            props.item.identifier +
                                            '&sortBy=created_at&sortDesc=true'}},[_vm._v(_vm._s(props.item.identifier))]),_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(props.item.identifier)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(_vm._s(_vm.mdiContentCopy))]),_vm._v(" Copy ")],1)]}},{key:"item.category",fn:function(props){return [_c('v-img',{attrs:{"alt":"Category Logo","contain":"","max-height":"16","max-width":"100","src":props.item.categoryLogo}})]}},{key:"item.status",fn:function(props){return [_c('v-chip',{attrs:{"color":props.item.callStatusColor,"dark":"","small":""}},[_vm._v(" "+_vm._s(props.item.callStatus)+" ")])]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h5',{staticClass:"text-h5 mb-4 mt-4"},[_vm._v(" Customer Details ")]),(
                                                        item.names.length >
                                                        0
                                                    )?_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"text-uppercase caption text--secondary"},[_vm._v(" Name ")]),_c('v-row',{staticClass:"caption"},[_c('ul',_vm._l((item.names),function(name){return _c('li',{key:name.value},[_vm._v(" "+_vm._s(name.value)+" ("+_vm._s(name.hits)+") ")])}),0)])],1):_vm._e(),(
                                                        item.emails.length >
                                                        0
                                                    )?_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"text-uppercase caption text--secondary"},[_vm._v(" Email ")]),_c('v-row',{staticClass:"caption"},[_c('ul',_vm._l((item.emails),function(email){return _c('li',{key:email.value},[_vm._v(" "+_vm._s(email.value)+" ("+_vm._s(email.hits)+") ")])}),0)])],1):_vm._e(),(
                                                        item.phoneNumbers
                                                            .length > 0
                                                    )?_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"text-uppercase caption text--secondary"},[_vm._v(" Phone Number ")]),_c('v-row',{staticClass:"caption"},[_c('ul',_vm._l((item.phoneNumbers),function(phoneNumber,index){return _c('li',{key:phoneNumber.value},[(
                                                                        _vm.$vuetify
                                                                            .breakpoint
                                                                            .mobile &&
                                                                        index ===
                                                                            0
                                                                    )?_c('a',{staticClass:"text-decoration-none",attrs:{"href":'tel:' +
                                                                        phoneNumber.value}},[_vm._v(_vm._s(phoneNumber.value))]):_c('span',[_vm._v(_vm._s(phoneNumber.value))]),_vm._v(" ("+_vm._s(phoneNumber.hits)+") ")])}),0)])],1):_vm._e(),(
                                                        item.userIds
                                                            .length > 0
                                                    )?_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"text-uppercase caption text--secondary"},[_vm._v(" User ID ")]),_c('v-row',{staticClass:"caption mb-2"},[_c('ul',_vm._l((item.userIds),function(id){return _c('li',{key:id.value},[_vm._v(" "+_vm._s(id.value)+" ("+_vm._s(id.hits)+") ")])}),0)])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h5',{staticClass:"text-h5 mt-4 mb-2"},[_vm._v(" Comments ")]),_c('v-timeline',{staticClass:"px-0",attrs:{"dense":""}},[_vm._l((item.comments),function(comment){return _c('v-timeline-item',{key:comment.id,attrs:{"color":item.callStatusColor,"small":""}},[_c('p',[_vm._v(" "+_vm._s(comment.comment)+" ")]),_c('p',{staticClass:"text--secondary caption mt-n3"},[_c('b',[_vm._v("-- "+_vm._s(_vm.name( comment.author )))]),_vm._v(" on "+_vm._s(_vm.formatTimestamp( comment.timestamp ))+" ")]),_c('div',{staticClass:"mt-n3"},[_c('delete-button',{attrs:{"text":false,"small":true,"with-icon":true,"disabled":_vm.isLoading,"item-text":"comment"},on:{"confirmed":function($event){return _vm.deleteComment(
                                                                        item.id,
                                                                        comment.id
                                                                    )}}})],1)])}),_c('v-timeline-item',{attrs:{"small":""}},[_c('v-textarea',{staticClass:"mt-3",attrs:{"disabled":_vm.isLoading,"dark":_vm.$vuetify
                                                                    .theme
                                                                    .dark,"rows":"2","error":_vm.formInputErrors.has(
                                                                    'comment'
                                                                ) ||
                                                                _vm.formInputErrors.has(
                                                                    'captcha'
                                                                ),"error-messages":_vm.formInputErrors.get(
                                                                    'comment'
                                                                ) ||
                                                                _vm.formInputErrors.get(
                                                                    'captcha'
                                                                ),"label":"Comment","placeholder":"Write a new comment here","outlined":""},model:{value:(
                                                                _vm.comment
                                                            ),callback:function ($$v) {
                                                                _vm.comment
                                                            =$$v},expression:"\n                                                                comment\n                                                            "}}),_c('div',{staticClass:"mt-n4"},[_c('loading-button',{attrs:{"small":true,"is-loading":_vm.isLoading},on:{"click":function($event){return _vm.storeComment(
                                                                        item.id
                                                                    )}}},[_vm._v(" Add Comment ")])],1)],1)],2)],1)],1)],1)]}}])})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }