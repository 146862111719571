
import { Vue, Component } from "vue-property-decorator";
import { mdiAlertCircle, mdiWhatsapp } from "@mdi/js";
import StatusApi from "@/services/api/status";
import { AxiosError, AxiosResponse } from "axios";
import Status from "@/models/status";
import { captureSentryException } from "@/plugins/sentry";
import { ApiResponse } from "@/services/api/axios";

@Component
export default class StatusAlert extends Vue {
    warningIcon: string = mdiAlertCircle;
    mdiWhatsapp: string = mdiWhatsapp;
    hasAlert = false;
    status: Status | null = null;
    timeout = 20 * 60 * 1000;

    loadStatus() {
        StatusApi.index()
            .then((response: AxiosResponse) => {
                this.updateStatus(response);
            })
            .catch((error: AxiosError<ApiResponse>) => {
                this.updateStatus(error.response);
            });
    }

    updateStatus(response: AxiosResponse | undefined) {
        try {
            if (
                !response?.data ||
                Object.keys(response.data).indexOf("timestamp") === -1
            ) {
                return;
            }

            this.status = new Status(response?.data);
            if (
                this.status.failures &&
                Object.keys(this.status.failures).length >= 0
            ) {
                this.hasAlert = true;
                setTimeout(this.loadStatus, this.timeout);
            } else {
                this.hasAlert = false;
            }
        } catch (e) {
            captureSentryException(e);
        }
    }

    mounted() {
        this.loadStatus();
    }
}
