import { AxiosResponse } from "axios";
import Axios from "./axios";

export interface CreateCustomerReviewInput {
    captcha: string;
    user_id: string;
    rating: number;
    message: string;
    order_id: string;
}

class CustomerReview {
    private axios: typeof Axios;

    constructor(axios: typeof Axios) {
        this.axios = axios;
    }

    store(review: CreateCustomerReviewInput): Promise<AxiosResponse> {
        return this.axios.post(`/customer-reviews`, review);
    }

    get(userId: string): Promise<AxiosResponse> {
        return this.axios.get(`/customer-reviews/${userId}`);
    }

    index(): Promise<AxiosResponse> {
        return this.axios.get(`/admin/customer-reviews`);
    }
}

export default new CustomerReview(Axios);
