import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import store from "@/store";
import { getCurrentUser } from "@/models/user";

Vue.use(VueRouter);

const DEFAULT_TITLE_ENDING = "Nyanga Pay";

export const ROUTE_NAMES = {
    HOME: "Home",
    ABOUT_US: "AboutUsIndex",
    CONTACT_US_INDEX: "ContactUsIndex",
    REFERRALS_INDEX: "ReferralsIndex",
    AFFILIATES_INDEX: "AffiliatesIndex",
    ORDERS_SHOW: "OrdersShow",
    ORDERS_INDEX: "OrdersIndex",
    TERMS_AND_CONDITIONS_SHOW: "TermsAndConditionsShow",
    PRIVACY_POLICY_SHOW: "PrivacyPolicyShow",
    AUTH_SIGN_IN: "AuthSignIn",
    USER_PROFILE: "UserProfile",
    AIRTIME_INDEX: "AirtimeIndex",

    ENEO_INDEX: "EneoIndex",
    CAMWATER_INDEX: "CamwaterIndex",
    CRYPTO_SELL: "CryptoSell",
    COINPAYMENTS_CHECKOUT: "CoinPaymentsCheckout",
    EXCHANGE_INDEX: "ExchangeIndex",
    CANALPLUS_INDEX: "CanalplusIndex",

    ADMIN_ORDERS_INDEX: "AdminOrdersIndex",
    ADMIN_EVENTS_INDEX: "AdminEventsIndex",
    ADMIN_REFERRALS_INDEX: "AdminReferralsIndex",
    ADMIN_CONTACT_MESSAGES_INDEX: "AdminContactMessagesIndex",
    ADMIN_CUSTOMER_REVIEWS_INDEX: "AdminCustomerFeedbackIndex",
    ADMIN_DASHBOARD_INDEX: "AdminDashboardIndex",
    ADMIN_CRM_INDEX: "AdminCrmIndex",

    INSTALL_INDEX: "InstallIndex",
    INSTALL_CHROME: "InstallChrome",
    INSTALL_FIREFOX: "InstallFirefox",
    INSTALL_SAFARI: "InstallSafari",
    FAQ_INDEX: "FaqIndex",
    FAQ_WHAT_IS_MY_DSTV_SMARTCARD_NUMBER: "FaqWhatIsMyDstvSmartcardNumber",
    FAQ_HOW_TO_CHECK_YOUR_DSTV_SMARTCARD_NUMBER:
        "FaqHowToCheckYourDSTVSmartcardNumber",
    FAQ_HOW_TO_CHECK_THE_SIGNAL_STRENGTH_OF_YOUR_DSTV_DECODER:
        "FaqHowTOCheckTheSignalStrengthOfYourDstvDecoder",
    FAQ_WHAT_PAYMENT_OPTIONS_ARE_AVAILABLE_ON_NYANGA_PAY:
        "FaqWhatPaymentOptionsAreAvailableOnNyangaPay",
    FAQ_WHY_WAS_I_NOT_RECONNECTED_AFTER_SUCCESSFUL_PAYMENT:
        "FaqWhyWasINotReconnectedAfterSuccessfullPayment",
    FAQ_HOW_DO_I_LOGIN_TO_MY_DSTV_ACCOUNT: "FaqHowDoILoginToMyDSTVAccount",
    FAQ_HOW_DO_I_UPGRADE_OR_DOWNGRADE_MY_DSTV_PACKAGE:
        "FaqHowDoIUpdateOrDowngradeMyDSTVPackage",
    FAQ_WHY_ARE_MY_DSTV_CHANNELS_NOT_SHOWING:
        "FaqWhyAreMyDStvChannelsNotShowing",
    FAQ_WHAT_DO_I_DO_IF_MY_DSTV_CHANNELS_ARE_NOT_SHOWING:
        "WhatDoIDoIfMyDstvChannelsAreNotShowing",
    FAQ_HOW_DO_I_CLEAR_E16_E30_E32_ERROR_MESSAGES_ON_MY_DSTV_DECODER:
        "FaqHowDoIClearE16E30E32ErrorMessagesOnMyDStvDecoder",
    FAQ_WHY_DO_I_GET_E48_32_ON_MY_TV: "FaqWhyDoIGetE4832OnMyTV",
    FAQ_WHAT_IS_DSTV_XTRAVIEW: "FaqWhatIsDStvExtraView",
    FAQ_HOW_DO_I_CLEAR_E48_32_ERROR_MESSAGES_ON_MY_DSTV_DECODER:
        "FaqHowDoIClearE4832ErrorMessagesOnMyDStvDecoder",
    FAQ_CAN_NYANGA_PAY_AUTOMATICALLY_WITHDRAW_MONEY_FROM_MY_MOBILE_MONEY_ACCOUNT:
        "FaqCanNyangapayAutomaticallyWithdrawMoneyFromMyMobileMoneyAccount",
    FAQ_MONEY_HAS_BEEN_DEDUCTED_FROM_MY_ACCOUNT_BUT_THE_TRANSACTION_WAS_NOT_SUCCESSFUL:
        "FaqMoneyHasBeenDeductedFromMyAccountButTheTransactionWasNotSuccessful",
    FAQ_NOVEMBER_2022_PRICE_UPDATE: "November2022PriceUpdate",
    FAQ_MAY_2023_PRICE_UPDATE: "May2023PriceUpdate",
    FAQ_MAY_2024_PRICE_UPDATE: "May2024PriceUpdate",
    DSTV_INDEX: "LandingPageDStv",
    DSTV_SHOW: "DStvShow",
    KITCHEN_SINK_LOGS: "KitchenSinkLogs",

    DSTV_CHANNELS: "DStvChannels",
    DSTV_CHANNELS_CONFAM: "DstvChannelsConfam",
    DSTV_CHANNELS_YANGA: "DstvChannelsYanga",
    DSTV_CHANNELS_COMPACT: "DstvChannelsCompact",
    DSTV_CHANNELS_COMPACT_PLUS: "DstvChannelsCompactPlus",
    DSTV_CHANNELS_PREMIUM: "DstvChannelsPremium",
    DSTV_EXPIRY_DATE_CHECKER: "DStvExpiryDateChecker",
    DSTV_PRICE_LOCK: "DstvPriceLock",
};

export const routes: Array<RouteConfig> = [
    {
        path: "/auth/sign-in",
        name: ROUTE_NAMES.AUTH_SIGN_IN,
        component: () => import("@/views/auth/AuthSignIn.vue"),
        meta: {
            title: "Sign In",
        },
    },
    {
        path: "/user/profile",
        name: ROUTE_NAMES.USER_PROFILE,
        component: () => import("@/views/user/UserProfileEdit.vue"),
        meta: {
            title: "Account Settings",
            auth: true,
            isNotAnonymous: true,
            sitemap: { ignoreRoute: true },
        },
    },
    {
        path: "/",
        name: ROUTE_NAMES.HOME,
        component: () => import("@/views/landing-page/LandingPageHome.vue"),
        meta: {
            title: "Pay your DStv subscription, Eneo bills, Camwater bills and buy Airtime online using MTN Mobile Money or Orange Money",
        },
    },
    {
        path: "/airtime",
        name: ROUTE_NAMES.AIRTIME_INDEX,
        component: () => import("@/views/airtime/AirtimeIndex.vue"),
        meta: {
            title: "Buy airtime for MTN, Orange, Camtel, neXttel and Yoomee online in Cameroon",
        },
    },
    {
        path: "/exchange",
        name: ROUTE_NAMES.EXCHANGE_INDEX,
        component: () => import("@/views/exchange/ExchangeIndex.vue"),
        meta: {
            title: "Transfer money from an MTN Mobile Money account into an Orange Money account or vice versa",
        },
    },
    {
        path: "/canalplus",
        name: ROUTE_NAMES.CANALPLUS_INDEX,
        component: () => import("@/views/canalplus/CanalPlusIndex.vue"),
        meta: {
            title: "Pay your CANAL+ subscription online using MTN Mobile Money, Orange Money or Bank Card",
        },
    },
    {
        path: "/eneo",
        name: ROUTE_NAMES.ENEO_INDEX,
        component: () => import("@/views/eneo/EneoIndex.vue"),
        meta: {
            title: "Pay your electricity (Eneo) bills Online in Cameroon",
        },
    },
    {
        path: "/camwater",
        name: ROUTE_NAMES.CAMWATER_INDEX,
        component: () => import("@/views/camwater/CamwaterIndex.vue"),
        meta: {
            title: "Pay your Camwater bills online in Cameroon",
        },
    },
    {
        path: "/sell-crypto",
        name: ROUTE_NAMES.CRYPTO_SELL,
        component: () => import("@/views/crypto/CryptoSell.vue"),
        meta: {
            title: "Sell Bitcoin (BTC), Ethereum (ETH) and Tether (USDT) using Mobile Money or Orange Money",
        },
    },
    {
        path: "/coinpayments/checkout/:orderId",
        name: ROUTE_NAMES.COINPAYMENTS_CHECKOUT,
        component: () =>
            import("@/views/coinpayments/CoinPaymentsCheckout.vue"),
        meta: {
            title: "Checkout with coinpayments gateway",
        },
    },
    {
        path: "/about-us",
        name: ROUTE_NAMES.ABOUT_US,
        component: () => import("@/views/about-us/AboutUsIndex.vue"),
        meta: {
            title: "About Us",
        },
    },
    {
        path: "/contact-us",
        name: ROUTE_NAMES.CONTACT_US_INDEX,
        component: () => import("@/views/contact-us/ContactUsIndex.vue"),
        meta: {
            title: "Contact Us",
        },
    },
    {
        path: "/referrals",
        name: ROUTE_NAMES.REFERRALS_INDEX,
        component: () => import("@/views/referrals/ReferralsIndex.vue"),
        meta: {
            title: "Referrals",
            auth: true,
            isNotAnonymous: true,
        },
    },
    {
        path: "/affiliates",
        name: ROUTE_NAMES.AFFILIATES_INDEX,
        component: () => import("@/views/affiliates/AffiliatesIndex.vue"),
        meta: {
            title: "Affiliates",
        },
    },
    {
        path: "/faq",
        name: ROUTE_NAMES.FAQ_INDEX,
        component: () => import("@/views/faq/FaqIndex.vue"),
        meta: {
            title: "Frequently Asked Questions",
        },
    },
    {
        path: "/faq/what-is-my-dstv-smartcard-iuc-number",
        name: ROUTE_NAMES.FAQ_WHAT_IS_MY_DSTV_SMARTCARD_NUMBER,
        component: () =>
            import("@/views/faq/FaqWhatIsMyDSTVSmartcardNumber.vue"),
        meta: {
            title: "What is My Smartcard/IUC Number?",
        },
    },
    {
        path: "/faq/how-to-check-your-dstv-smartcard-number",
        name: ROUTE_NAMES.FAQ_HOW_TO_CHECK_YOUR_DSTV_SMARTCARD_NUMBER,
        component: () =>
            import("@/views/faq/FaqHowToCheckYourDSTVSmartcardNumber.vue"),
        meta: {
            title: "How to check your DStv smart card Number?",
        },
    },
    {
        path: "/faq/dstv-decoder-signal-strength",
        name: ROUTE_NAMES.FAQ_HOW_TO_CHECK_THE_SIGNAL_STRENGTH_OF_YOUR_DSTV_DECODER,
        component: () =>
            import(
                "@/views/faq/FaqHowToCheckTheSignalStrengthOfYourDstvDecoder.vue"
            ),
        meta: {
            title: "How to check the signal strength of your DStv decoder",
        },
    },
    {
        path: "/faq/what-payment-options-are-available-on-nyanga-pay",
        name: ROUTE_NAMES.FAQ_WHAT_PAYMENT_OPTIONS_ARE_AVAILABLE_ON_NYANGA_PAY,
        component: () =>
            import("@/views/faq/FaqWhatPaymentOptionsAreAvailable.vue"),
        meta: {
            title: "What Payment Options Are Available on Nyanga Pay?",
        },
    },
    {
        path: "/faq/why-was-i-not-reconnected-after-successful-payment",
        name: ROUTE_NAMES.FAQ_WHY_WAS_I_NOT_RECONNECTED_AFTER_SUCCESSFUL_PAYMENT,
        component: () =>
            import(
                "@/views/faq/FaqWhyWasINotReconnectedAfterSuccessfullPayment.vue"
            ),
        meta: {
            title: "Why Wasn't I Reconnected Immediately After a Successful Payment?",
        },
    },
    {
        path: "/faq/how-do-i-login-to-my-dstv-account",
        name: ROUTE_NAMES.FAQ_HOW_DO_I_LOGIN_TO_MY_DSTV_ACCOUNT,
        component: () =>
            import("@/views/faq/FaqHowDoILoginToMyDStvAccount.vue"),
        meta: {
            title: "How do I login to my DStv Account?",
        },
    },
    {
        path: "/faq/how-do-i-upgrade-or-downgrade-my-dstv-package",
        name: ROUTE_NAMES.FAQ_HOW_DO_I_UPGRADE_OR_DOWNGRADE_MY_DSTV_PACKAGE,
        component: () =>
            import("@/views/faq/FaqHowDoIUpgrdeOrDowngradeMyDStvPackage.vue"),
        meta: {
            title: "How do I upgrade or Downgrade my DStv Package?",
        },
    },
    {
        path: "/faq/why-are-my-dstv-channels-not-showing",
        name: ROUTE_NAMES.FAQ_WHY_ARE_MY_DSTV_CHANNELS_NOT_SHOWING,
        component: () =>
            import("@/views/faq/FaqWhyAreMyChannelsNotShowing.vue"),
        meta: {
            title: "Why are my DStv channels not showing",
        },
    },
    {
        path: "/faq/what-do-i-do-if-my-dstv-channels-are-not-showing",
        name: ROUTE_NAMES.FAQ_WHAT_DO_I_DO_IF_MY_DSTV_CHANNELS_ARE_NOT_SHOWING,
        component: () =>
            import("@/views/faq/FaqWhatDoIDoIfMyDStvChannelsAreNotShowing.vue"),
        meta: {
            title: "What do I do if my DStv channels are not showing?",
        },
    },
    {
        path: "/faq/how-do-i-clear-e16-e30-e32-error-messages-on-my-dstv-decoder",
        name: ROUTE_NAMES.FAQ_HOW_DO_I_CLEAR_E16_E30_E32_ERROR_MESSAGES_ON_MY_DSTV_DECODER,
        component: () =>
            import(
                "@/views/faq/FaqHowDoIClearE16E30E32ErrorMessagesOnMyDStvDecoder.vue"
            ),
        meta: {
            title: "How do I Clear E16, E30 or E32 error messages on my Decoder?",
        },
    },
    {
        path: "/faq/why-do-i-get-e48-32-on-my-tv",
        name: ROUTE_NAMES.FAQ_WHY_DO_I_GET_E48_32_ON_MY_TV,
        component: () => import("@/views/faq/FaqWhyDoIGetE4832OnMyTV.vue"),
        meta: {
            title: "Why do I get E48-32 on my TV?",
        },
    },
    {
        path: "/faq/what-is-dstv-xtraview",
        name: ROUTE_NAMES.FAQ_WHAT_IS_DSTV_XTRAVIEW,
        component: () => import("@/views/faq/FaqWhatIsDStvXtraView.vue"),
        meta: {
            title: "Why is DStv Xtra View?",
        },
    },
    {
        path: "/faq/how-do-i-clear-e48-32-error-on-my-tv",
        name: ROUTE_NAMES.FAQ_HOW_DO_I_CLEAR_E48_32_ERROR_MESSAGES_ON_MY_DSTV_DECODER,
        component: () =>
            import("@/views/faq/FaqHowDoIClearE4832ErrorOnMyTV.vue"),
        meta: {
            title: "How do I clear E48-32 error on my TV?",
        },
    },
    {
        path: "/faq/can-nyangapay-automatically-withdraw-money-from-my-mobile-money-account",
        name: ROUTE_NAMES.FAQ_CAN_NYANGA_PAY_AUTOMATICALLY_WITHDRAW_MONEY_FROM_MY_MOBILE_MONEY_ACCOUNT,
        component: () =>
            import(
                "@/views/faq/FaqCanNyangaPayAutomaticallyWithdrawMoneyFormMyMobileMoneyAccount.vue"
            ),
        meta: {
            title: "Can Nyanga pay automatically withdraw money from my mobile money account?",
        },
    },
    {
        path: "/faq/dstv-price-lock",
        name: ROUTE_NAMES.DSTV_PRICE_LOCK,
        component: () => import("@/views/faq/FaqDstvPriceLock.vue"),
        meta: {
            title: "What is the DStv price lock feature",
        },
    },
    {
        path: "/faq/dstv-price-update-november-2022",
        name: ROUTE_NAMES.FAQ_NOVEMBER_2022_PRICE_UPDATE,
        component: () => import("@/views/faq/FaqNovember2022PriceUpdate.vue"),
        meta: {
            title: "DStv subscription just got cheaper - November 2022",
        },
    },
    {
        path: "/faq/dstv-price-update-may-2023",
        name: ROUTE_NAMES.FAQ_MAY_2023_PRICE_UPDATE,
        component: () => import("@/views/faq/FaqMay2023PriceUpdate.vue"),
        meta: {
            title: "DStv Price Update  - May 2023",
        },
    },
    {
        path: "/faq/dstv-price-update-may-2024",
        name: ROUTE_NAMES.FAQ_MAY_2024_PRICE_UPDATE,
        component: () => import("@/views/faq/FaqMay2024PriceUpdate.vue"),
        meta: {
            title: "DStv Price Update  - May 2024",
        },
    },
    {
        path: "/faq/money-has-been-deducted-from-my-mobile-money-account-but-the-transaction-was-not-successfull",
        name: ROUTE_NAMES.FAQ_MONEY_HAS_BEEN_DEDUCTED_FROM_MY_ACCOUNT_BUT_THE_TRANSACTION_WAS_NOT_SUCCESSFUL,
        component: () =>
            import(
                "@/views/faq/FaqMoneyHasBeenDeductedFromMyAccountButTheTransactionWasNotSuccessful.vue"
            ),
        meta: {
            title: "Money has been deducted from my Mobile Money account but the transaction was not successful?",
        },
    },
    {
        path: "/terms-and-conditions",
        name: ROUTE_NAMES.TERMS_AND_CONDITIONS_SHOW,
        component: () =>
            import("@/views/terms-and-conditions/TermsAndConditionsShow.vue"),
        meta: {
            title: "Terms and Conditions",
        },
    },
    {
        path: "/privacy-policy",
        name: ROUTE_NAMES.PRIVACY_POLICY_SHOW,
        component: () => import("@/views/privacy-policy/PrivacyPolicyShow.vue"),
        meta: {
            title: "Privacy Policy",
        },
    },
    {
        path: "/orders/:orderId",
        name: ROUTE_NAMES.ORDERS_SHOW,
        component: () => import("@/views/orders/OrdersShow.vue"),
        meta: {
            title: "Order Details",
            auth: true,
            sitemap: { ignoreRoute: true },
        },
    },
    {
        path: "/orders",
        name: ROUTE_NAMES.ORDERS_INDEX,
        component: () => import("@/views/orders/OrdersIndex.vue"),
        meta: {
            auth: true,
            title: "My Orders",
        },
    },
    {
        path: "/install",
        name: ROUTE_NAMES.INSTALL_INDEX,
        component: () => import("@/views/install/InstallIndex.vue"),
        meta: {
            title: "Install App",
            sitemap: { ignoreRoute: true },
        },
    },
    {
        path: "/install/chrome",
        name: ROUTE_NAMES.INSTALL_CHROME,
        component: () => import("@/views/install/InstallChrome.vue"),
        meta: {
            title: "Install App on Google Chrome",
        },
    },
    {
        path: "/install/firefox",
        name: ROUTE_NAMES.INSTALL_FIREFOX,
        component: () => import("@/views/install/InstallFirefox.vue"),
        meta: {
            title: "Install App on Firefox",
        },
    },
    {
        path: "/install/safari",
        name: ROUTE_NAMES.INSTALL_SAFARI,
        component: () => import("@/views/install/InstallSafari.vue"),
        meta: {
            title: "Install App on Safari",
        },
    },
    {
        path: "/dstv",
        name: ROUTE_NAMES.DSTV_INDEX,
        component: () => import("@/views/dstv/DstvIndex.vue"),
        meta: {
            title: "Pay DStv Subscription Using Mobile Money/Orange Money",
        },
    },
    {
        path: "/dstv/:orderId",
        name: ROUTE_NAMES.DSTV_SHOW,
        component: () => import("@/views/dstv/DstvShow.vue"),
        meta: {
            title: "Pay DStv Subscription Using Mobile Money/Orange Money",
        },
    },
    {
        path: "/admin/orders",
        name: ROUTE_NAMES.ADMIN_ORDERS_INDEX,
        component: () => import("@/views/admin/AdminOrdersIndex.vue"),
        meta: {
            title: "All Orders",
            admin: true,
            sitemap: { ignoreRoute: true },
        },
    },
    {
        path: "/admin/events",
        name: ROUTE_NAMES.ADMIN_EVENTS_INDEX,
        component: () => import("@/views/admin/AdminEventsIndex.vue"),
        meta: {
            title: "All Events",
            admin: true,
            sitemap: { ignoreRoute: true },
        },
    },
    {
        path: "/admin/referrals",
        name: ROUTE_NAMES.ADMIN_REFERRALS_INDEX,
        component: () => import("@/views/admin/AdminReferralsIndex.vue"),
        meta: {
            title: "All Referrers",
            admin: true,
            sitemap: { ignoreRoute: true },
        },
    },
    {
        path: "/admin/contact-messages",
        name: ROUTE_NAMES.ADMIN_CONTACT_MESSAGES_INDEX,
        component: () => import("@/views/admin/AdminContactMessagesIndex.vue"),
        meta: {
            title: "All Contact Messages",
            admin: true,
            sitemap: { ignoreRoute: true },
        },
    },
    {
        path: "/admin/customer-reviews",
        name: ROUTE_NAMES.ADMIN_CUSTOMER_REVIEWS_INDEX,
        component: () => import("@/views/admin/AdminCustomerReviewsIndex.vue"),
        meta: {
            title: "Customer Reviews",
            admin: true,
            sitemap: { ignoreRoute: true },
        },
    },
    {
        path: "/admin/dashboard",
        name: ROUTE_NAMES.ADMIN_DASHBOARD_INDEX,
        component: () => import("@/views/admin/AdminDashboardIndex.vue"),
        meta: {
            title: "Dashboard",
            admin: true,
            sitemap: { ignoreRoute: true },
        },
    },
    {
        path: "/kitchen-sink",
        name: ROUTE_NAMES.KITCHEN_SINK_LOGS,
        component: () => import("@/views/logs/LogsIndex.vue"),
        meta: {
            title: "Kitchen Sink",
            admin: true,
            sitemap: { ignoreRoute: true },
        },
    },
    {
        path: "/admin/crm",
        name: ROUTE_NAMES.ADMIN_CRM_INDEX,
        component: () => import("@/views/admin/AdminCrmIndex.vue"),
        meta: {
            title: "CRM",
            admin: true,
            sitemap: { ignoreRoute: true },
        },
    },
    {
        path: "/invite",
        redirect: () => {
            return (
                "/?invite=" +
                new URLSearchParams(window.location.search).get("code")
            );
        },
    },
    {
        path: "/dstv-channels",
        name: ROUTE_NAMES.DSTV_CHANNELS,
        component: () => import("@/views/channels/ChannelsIndex.vue"),
        meta: {
            title: "DStv Channel List",
        },
    },
    {
        path: "/dstv-channels/yanga-package",
        name: ROUTE_NAMES.DSTV_CHANNELS_YANGA,
        component: () => import("@/views/channels/ChannelsList.vue"),
        meta: {
            title: "DStv Yanga Channel List",
        },
    },
    {
        path: "/dstv-channels/confam-package",
        name: ROUTE_NAMES.DSTV_CHANNELS_CONFAM,
        component: () => import("@/views/channels/ChannelsList.vue"),
        meta: {
            title: "DStv Confam Channel List",
        },
    },
    {
        path: "/dstv-channels/compact-package",
        name: ROUTE_NAMES.DSTV_CHANNELS_COMPACT,
        component: () => import("@/views/channels/ChannelsList.vue"),
        meta: {
            title: "DStv Compact Channel List",
        },
    },
    {
        path: "/dstv-channels/compact-plus-package",
        name: ROUTE_NAMES.DSTV_CHANNELS_COMPACT_PLUS,
        component: () => import("@/views/channels/ChannelsList.vue"),
        meta: {
            title: "DStv Compact Plus Channel List",
        },
    },
    {
        path: "/dstv-channels/premium-package",
        name: ROUTE_NAMES.DSTV_CHANNELS_PREMIUM,
        component: () => import("@/views/channels/ChannelsList.vue"),
        meta: {
            title: "DStv Premium Channel List",
        },
    },
    {
        path: "/dstv-package-checker",
        name: ROUTE_NAMES.DSTV_EXPIRY_DATE_CHECKER,
        component: () => import("@/views/pages/DstvPackageChecker.vue"),
        meta: {
            title: "Get Your DStv Package And Subscription Expiry Date.",
        },
    },
    {
        path: "*",
        component: () => import("@/views/pages/Page404.vue"),
        meta: {
            title: "404: Not Found",
            sitemap: { ignoreRoute: true },
        },
    },
];

export const addParamsToLocation = (
    route: Route,
    params: Record<string, string | number | boolean | null>
) => {
    history.pushState(
        {},
        route.meta?.title,
        route.path +
            "?" +
            Object.keys(params)
                .filter((key) => params[key] !== null)
                .map((key: string): string => {
                    return (
                        encodeURIComponent(key) +
                        "=" +
                        encodeURIComponent((params[key] ?? "").toString())
                    );
                })
                .join("&")
    );
};

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

router.beforeEach(async (to, from, next) => {
    await Promise.all([
        store.dispatch("setLoading", true),
        store.dispatch("setPreviousPath", from.path),
    ]);

    await getCurrentUser();
    const requiresNonAnonymous = to.matched.some(
        (record) => record.meta.isNotAnonymous
    );
    if (requiresNonAnonymous && store.getters.userIsAnonymous) {
        next({
            name: ROUTE_NAMES.AUTH_SIGN_IN,
            query: {
                info: "You need to create an account to continue",
                afterAuthPath: to.path,
            },
        });
        return;
    }

    const requiresAuth = to.matched.some((record) => record.meta.auth);
    if (requiresAuth && !store.getters.user && !store.getters.userIsAnonymous) {
        next({
            name: ROUTE_NAMES.AUTH_SIGN_IN,
            query: {
                info: "You need to login to continue",
                afterAuthPath: to.path,
            },
        });
        return;
    }

    const requiresAdmin = to.matched.some((record) => record.meta.admin);
    if (
        requiresAdmin &&
        !store.getters.userIsAdmin &&
        !store.getters.userIsCustomerServiceAgent
    ) {
        next({
            name: ROUTE_NAMES.AUTH_SIGN_IN,
            query: {
                info: "You need to login as admin continue",
                afterAuthPath: to.path,
            },
        });
    }

    next();
});

router.afterEach(async (to) => {
    await store.dispatch("setLoading", false);
    Vue.nextTick(() => {
        document.title = to?.meta?.title + " - " + DEFAULT_TITLE_ENDING;
    });
});

export default router;
