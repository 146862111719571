
import { Component, Vue } from "vue-property-decorator";
import FailedPaymentsChart from "@/components/FailedPaymentsChart.vue";
import TestOrdersChart from "@/components/TestOrdersChart.vue";
import TotalPaymentsChart from "@/components/TotalPaymentsChart.vue";
import AdminDashboardChart from "@/components/AdminDashboardChart.vue";
import CrmColumn from "@/components/CrmColumn.vue";
import CrmEntryApi from "@/services/api/crm-entry";
import { ensureAuthenticated } from "@/plugins/firebase";
import { AxiosError, AxiosResponse } from "axios";
import CrmEntry, { SerializedCrmEntry } from "@/models/crm-entry";
import { captureSentryException } from "@/plugins/sentry";
import { ApiResponse } from "@/services/api/axios";
import { DatatableFooterProps, DefaultFooterProps } from "@/types/veutify";
import { mdiMagnify, mdiContentCopy } from "@mdi/js";
import { differenceInDays, format, formatDistance } from "date-fns";
import DeleteButton from "@/components/DeleteButton.vue";
import { userNameFromId } from "@/models/user";
import ErrorService, { ValidationErrors } from "@/services/errors";
import LoadingButton from "@/components/LoadingButton.vue";

@Component({
    components: {
        LoadingButton,
        DeleteButton,
        CrmColumn,
        AdminDashboardChart,
        TotalPaymentsChart,
        TestOrdersChart,
        FailedPaymentsChart,
    },
})
export default class AdminCrmIndex extends Vue {
    isLoading = true;
    crmEntries = Array<CrmEntry>();
    query: string = "";
    comment = "";
    mdiMagnify: string = mdiMagnify;
    mdiContentCopy: string = mdiContentCopy;
    formInputErrors: ValidationErrors = new ValidationErrors();
    footerProps: DatatableFooterProps = DefaultFooterProps;
    headers = [
        {
            text: "Last Paid At",
            value: "lastOrderDate",
        },
        {
            text: "Smartcard (IUC)",
            value: "identifier",
            sortable: false,
        },
        {
            text: "Category",
            value: "category",
            sortable: false,
        },
        {
            text: "Order Count",
            value: "orderCount",
        },
        {
            text: "Comment Count",
            value: "commentCount",
        },
        {
            text: "Status",
            value: "status",
        },
        { text: "Details", value: "data-table-expand" },
    ];

    loadCrmEntries() {
        this.isLoading = true;
        CrmEntryApi.adminIndex()
            .then((response: AxiosResponse) => {
                this.crmEntries = response.data.data.map(
                    (entry: SerializedCrmEntry) => {
                        return new CrmEntry(entry);
                    }
                );
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isLoading = false;
            });
    }

    handleAxiosSuccess(response: AxiosResponse) {
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.SUCCESS,
            response.data?.message || response.statusText
        );
        this.comment = "";
        this.loadCrmEntries();
        setTimeout(() => {
            this.isLoading = false;
        }, 2000);
    }

    emitErrorNotification(error: Error) {
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            error.message
        );
    }

    deleteComment(crmEntryId: string, commentId: string) {
        this.isLoading = true;
        CrmEntryApi.adminDeleteComment(crmEntryId, commentId)
            .then(this.handleAxiosSuccess)
            .catch(this.handleAxiosError);
    }

    handleAxiosError(error: AxiosError<ApiResponse>) {
        captureSentryException(error);
        this.emitErrorNotification(
            new Error(error?.response?.data?.message ?? error.message)
        );
    }

    daysAgo(value: Date): string {
        const diffInDays = differenceInDays(new Date(), value);
        if (diffInDays > 1) {
            return `${diffInDays} days ago`;
        }
        return formatDistance(value, new Date(), { addSuffix: true });
    }

    async copy(value: string) {
        try {
            await navigator.clipboard.writeText(value ?? "");
            this.$root.$emit(
                this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                `"${value}" has been copied to your clipboard`
            );
        } catch (error) {
            captureSentryException(error);
        }
    }

    name(id: string): string {
        return userNameFromId(id);
    }

    formatTimestamp(value: Date): string {
        return format(value, "PPP");
    }

    storeComment(crmEntryId: string) {
        this.isLoading = true;
        this.formInputErrors = new ValidationErrors();
        this.$recaptcha("store_crm_entry_comment")
            .then((captcha) => {
                CrmEntryApi.adminStoreComment(crmEntryId, this.comment, captcha)
                    .then(this.handleAxiosSuccess)
                    .catch((error: AxiosError<ApiResponse>) => {
                        this.formInputErrors =
                            ErrorService.getValidationErrors(error);
                        this.handleAxiosError(error);
                    });
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isLoading = false;
            });
    }

    mounted() {
        ensureAuthenticated().finally(() => {
            this.loadCrmEntries();
        });
    }
}
